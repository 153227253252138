import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'app/auth/service';
import { Permission } from '../models/permission.interface';
// import { locale as english } from './i18n/en';
// import { locale as hungarian } from './i18n/hu';
// import { locale as chinese } from './i18n/cn';
import { CoreTranslationService } from '@core/services/translation.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  /**
   *
   * @param {Router} _router
   * @param {AuthenticationService} _authenticationService
   * @param _coreTranslationService
   * @param _toastService
   */
  constructor(private _router: Router,
              private _authenticationService: AuthenticationService,
              private _coreTranslationService: CoreTranslationService,
              private _toastService: ToastrService,) {}

  // canActivate
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this._authenticationService.currentUserValue;

    if (currentUser) {
      // check if route is restricted for the user
      const routeAccess = route.data.routeAccess;
      const userPermissions = this.transformPermissionsToString(currentUser.roles[0]?.permissions);

      if(routeAccess){
        if(userPermissions.includes(routeAccess)){
          return true;
        }
        this._toastService.error('', 'Hozzáférés megtagadva!');
        return false;
      }

      // authorised because the lack of routeAccess means it's free to all authenticated users so return true
      return true;
    }

    // not logged in so redirect to login page with the return url
    this._router.navigate(['/pages/authentication/login-v2'], { queryParams: { returnUrl: state.url } });
    return false;

  }

  private transformPermissionsToString(permissions: Permission[]): string[] | null{
    if(permissions){
      return permissions.map(permission => permission.name);
    }
    return null;
  }
}
