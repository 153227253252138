export const locale = {
    lang: 'hu',
    data: {
      MENU: {
        DASHBOARD: 'Vezérlőpult',
        GOODS: 'Áruk',
        PRODUCTS: 'Termékek',
        STOCKS: 'Készlet',
        BUY: 'Vásárlás',
        CATEGORIES: 'Kategóriák',
        ORDERS: 'Rendelések',
        NAGYBANI: 'Nagybani',
        SELL: 'Eladás',
        SUPPLIERS: 'Beszállítók',
        SYSTEM: 'Rendszer',
        USERS: 'Felhasználók',
        ROLES: 'Jogok',
        ATTRIBUTES: 'Attribútum definíciók',
        LOGS: 'Naplók',
        LOG_ACTIONS: 'Műveletek',
        LOG_LOGIN: 'Bejelentkezések',
        LOG_SELL: 'Eladások',
        LOG_BUY: 'Vásárlások',
        LOG_STOCK: 'Készlet',
        BOSSPANEL: 'Boss panel',
        SETTINGS: 'Beállítások',
        BADGENEW: 'ÚJ',
        BADGESOON: 'Hamarosan',
      }
    }
  }
