import { CoreMenu } from '@core/types'

export const menu: CoreMenu[] = [
  {
    id: 'dashboard',
    title: 'Dashboard',
    translate: 'MENU.DASHBOARD',
    type: 'item',
    icon: 'compass',
    url: 'pages/dashboard'
  },
  // User Interface
  {
    id: 'bosspanel',
    title: 'Bosspanel',
    permission: 'boss.index',
    translate: 'MENU.BOSSPANEL',
    type: 'item',
    icon: 'user',
    url: 'pages/bosspanel'
  },
  {
    id: 'goods',
    title: 'Goods',
    translate: 'MENU.GOODS',
    type: 'collapsible',
    icon: 'package',
    children:[
      {
        id: 'products',
        title: 'Products',
        permission: 'products.index',
        translate: 'MENU.PRODUCTS',
        type: 'item',
        icon: 'align-justify',
        url: 'pages/products'
      },
      {
        id: 'stocks',
        title: 'Stocks',
        permission: 'stocks.show',
        translate: 'MENU.STOCKS',
        type: 'item',
        icon: 'package',
        url: 'pages/stocks'
      },
      {
        id: 'categories',
        title: 'Categories',
        permission: 'categories.index',
        translate: 'MENU.CATEGORIES',
        type: 'item',
        icon: 'folder',
        url: 'pages/categories'
      },
      {
        id: 'buy',
        title: 'buy',
        permission: 'buy.index',
        translate: 'MENU.BUY',
        type: 'item',
        icon: 'database',
        url: 'pages/buy'
      },
      {
        id: 'suppliers',
        title: 'Suppliers',
        permission: 'suppliers.index',
        translate: 'MENU.SUPPLIERS',
        type: 'item',
        icon: 'truck',
        url: 'pages/suppliers'
      }
    ]
  },
  {
    id: 'sell',
    title: 'Sell',
    translate: 'MENU.SELL',
    type: 'collapsible',
    icon: 'shopping-cart',
    children: [
      {
        id: 'salelist',
        title: 'Orders',
        permission: 'sell.index',
        translate: 'MENU.ORDERS',
        type: 'item',
        icon: 'shopping-bag',
        url: 'pages/orders'
      },
      {
        id: 'nagybani',
        title: 'Nagybani',
        permission: 'sell.store',
        translate: 'MENU.NAGYBANI',
        type: 'item',
        icon: 'dollar-sign',
        url: 'pages/sell',
      }
    ]
  },
  {
    id: 'system',
    title: 'System',
    translate: 'MENU.SYSTEM',
    type: 'collapsible',
    icon: 'settings',
    children: [
      {
        id: 'roles',
        title: 'Roles',
        permission: 'roles.index',
        translate: 'MENU.ROLES',
        type: 'item',
        icon: 'tool',
        url: 'pages/roles'
      },
      {
        id: 'users',
        title: 'Users',
        permission: 'users.index',
        translate: 'MENU.USERS',
        type: 'item',
        icon: 'users',
        url: 'pages/users'
      },
      {
        id: 'attributes',
        title: 'Attributes',
        permission: 'attribute-definitions.index',
        translate: 'MENU.ATTRIBUTES',
        type: 'item',
        icon: 'archive',
        url: 'pages/attributes'
      },
      {
        id: 'settings',
        title: 'Settings',
        permission: 'settings.index',
        translate: 'MENU.SETTINGS',
        type: 'item',
        icon: 'settings',
        url: 'pages/settings'
      },
    ]
  },
  {
    id: 'logs',
    title: 'Logs',
    translate: 'MENU.LOGS',
    type: 'collapsible',
    icon: 'file-text',
    badge: {
      translate: 'MENU.BADGENEW',
      classes: 'badge badge-glow badge-success'
    },
    children: [
      {
        id: 'logactions',
        title: 'Log Actions',
        permission: 'logs.actions',
        translate: 'MENU.LOG_ACTIONS',
        type: 'item',
        icon: 'file-text',
        url: 'pages/logs/actions'
      },
      {
        id: 'loglogin',
        title: 'Log Login',
        permission: 'logs.logins',
        translate: 'MENU.LOG_LOGIN',
        type: 'item',
        icon: 'file-text',
        url: 'pages/logs/logins'
      },
      {
        id: 'logsell',
        title: 'Log Sell',
        permission: 'logs.sell',
        translate: 'MENU.LOG_SELL',
        type: 'item',
        icon: 'file-text',
        url: 'pages/logs/sell',
      },
      {
        id: 'logbuy',
        title: 'Log Buy',
        permission: 'logs.buy',
        translate: 'MENU.LOG_BUY',
        type: 'item',
        icon: 'file-text',
        url: 'pages/logs/buy',
      },
      {
        id: 'logstock',
        title: 'Log Stock',
        permission: 'logs.stocks',
        translate: 'MENU.LOG_STOCK',
        type: 'item',
        icon: 'file-text',
        url: 'pages/logs/stock',
        badge: {
          translate: 'MENU.BADGENEW',
          classes: 'badge badge-glow badge-success'
        },
      }
    ]
  }
]
