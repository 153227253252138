import { Component, Input } from '@angular/core';
import { CoreMenuItem } from '@core/types';
import { AuthenticationService } from 'app/auth/service';

@Component({
  selector: '[core-menu-vertical-item]',
  templateUrl: './item.component.html'
})
export class CoreMenuVerticalItemComponent {
  @Input()
  item: CoreMenuItem;
  constructor(protected _authenticationService: AuthenticationService) {
  }
}
