export const locale = {
    lang: 'cn',
    data: {
        MENU: {
            DASHBOARD: '仪表板',
            GOODS: '商品',
            PRODUCTS: '产品',
            STOCKS: '股票',
            BUY: '入库',
            CATEGORIES: '类别',
            ORDERS: '订单',
            NAGYBANI: '创建订单',
            SELL: '销售',
            SUPPLIERS: '供应商',
            SYSTEM: '系统',
            USERS: '用户',
            ROLES: '角色',
            ATTRIBUTES: '属性',
            LOGS: '日志',
            LOG_ACTIONS: '操作日志',
            LOG_LOGIN: '登录日志',
            LOG_SELL: '销售日志',
            LOG_BUY: '采购日志',
            LOG_STOCK: '库存日志',
            BOSSPANEL: '老板面板',
            SETTINGS: '设置',
            BADGENEW: '新',
            BADGESOON: '即将',
        }
    }
}
