import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from 'environments/environment';
import { User, Role } from 'app/auth/models';
import { ToastrService } from 'ngx-toastr';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  //public
  public currentUser: Observable<User>;

  //private
  private currentUserSubject: BehaviorSubject<User>;
  /**
   *
   * @param {HttpClient} _http
   * @param {ToastrService} _toastrService
   */
  constructor(private _http: HttpClient, private _toastrService: ToastrService) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  // getter: currentUserValue
  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  /**
   *  Confirms if user is admin
   */
  get isAdmin() {
    return this.currentUser && this.currentUserSubject.value && this.currentUserSubject.value.roles.some(role => role.name === 'Admin');
  }

  /**
   *  Confirms if user is client
   */
  get isClient() {
    return this.currentUser && this.currentUserSubject.value && this.currentUserSubject.value.roles.some(role => role.name === 'Client');
  }

  get isLoggedIn(){
    return this.currentUser;
  }

  public hasPermission(permName: string): boolean{
    return this.currentUser && this.currentUserSubject.value && this.currentUserSubject.value.roles.some(role => role.permissions.some(perm => perm.name === permName));
  }

  /**
   * User login
   *
   * @param email
   * @param password
   * @returns user
   */
  login(username: string, password: string) {
    return this._http
      .post<any>(`${environment.apiUrl}/auth/login`, { username, password })
      .pipe(
        map(response => {
          // login successful if there's a jwt token in the response
          //console.log(response);
          if (response.user && response.token) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            let user = response.user;
            user = {...user, token: response.token};
            localStorage.setItem('currentUser', JSON.stringify(user));

            // Display welcome toast!
            setTimeout(() => {
              this._toastrService.success(
                ' ' +
                  
                  ' ',
                'Sikeres bejelentkezés, ' + response.user.name + '!',
                { toastClass: 'toast ngx-toastr', closeButton: true }
              );
            }, 2500);
           
            
            // notify
            this.currentUserSubject.next(user);
          }else{
            //console.log('Hiba');
          }

          return response;
        })
      );
  }

  /**
   * User logout
   *
   */
  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    localStorage.removeItem('loggedIn');
    //localStorage.clear();
    // notify
    this.currentUserSubject.next(null);
  }


}
