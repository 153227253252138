export const locale = {
  lang: 'en',
  data: {
    MENU: {
      DASHBOARD: 'Dashboard',
      GOODS: 'Goods',
      PRODUCTS: 'Products',
      STOCKS: 'Stock',
      BUY: 'Warehouse',
      CATEGORIES: 'Categories',
      ORDERS: 'Orders',
      NAGYBANI: 'Create order',
      SELL: 'Sell',
      SUPPLIERS: 'Suppliers',
      SYSTEM: 'System',
      USERS: 'Users',
      ROLES: 'Roles',
      ATTRIBUTES: 'Attributes',
      LOGS: 'Logs',
      LOG_ACTIONS: 'Actions',
      LOG_LOGIN: 'Logins',
      LOG_SELL: 'Sales',
      LOG_BUY: 'Purchases',
      LOG_STOCK: 'Stock',
      BOSSPANEL: 'Boss panel',
      SETTINGS: 'Setting',
      BADGENEW: 'NEW',
      BADGESOON: 'Coming soon',
    }
  }
}
