import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'environments/environment';
import { User } from 'app/auth/models';

@Injectable({ providedIn: 'root' })
export class UserService {
  /**
   *
   * @param {HttpClient} _http
   */
  constructor(private _http: HttpClient) {}

  /**
   * Get all users
   */
  getAll() {
    return this._http.get<User[]>(`${environment.apiUrl}/users`).toPromise();
  }

  /**
   * Get user by id
   */
  getById(id: number) {
    return this._http.get<User>(`${environment.apiUrl}/users/${id}`).toPromise();
  }

  /**
   * Update existing user
   */
  update(id: number, data: any){
    return this._http.put(`${environment.apiUrl}/users/${id}`, data).toPromise();
  }

  /**
   * Delete existing user
   */
  delete(id: number){
    return this._http.delete(`${environment.apiUrl}/users/${id}`).toPromise();
  }

  /**
   * Create new user
   */
  create(data: any){
    return this._http.post(`${environment.apiUrl}/users`, data).toPromise();
  }
}
